<template>
  <router-view v-if="isRouterAlive" />
</template>

<script>
import { showToast, showDialog } from 'vant';
export default {
  name: 'App',
  components: {
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  async mounted() {
    var _this = this
    if (window.ethereum) {


      if (!window.ethereum.isGD) {
        window.ethereum.on("accountsChanged", async function (accounts) {
          if (accounts[0]) {
            _this.$store.commit('setAccount', accounts[0])
            _this.$router.replace('/')
            _this.$store.commit('setTabActINdex', '0')
            _this.isRouterAlive = false
            _this.$nextTick(() => {
              _this.isRouterAlive = true
            })
          } else {
            _this.$store.commit('setAccount', '')
            _this.$router.replace('/')
          }
        });
      } else {
        setTimeout(async () => {
          const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
          _this.$store.commit('setAccount', accounts[0])
        }, 1000)
      }
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      _this.$store.commit('setAccount', accounts[0])
    } else {
      return showToast({
        className: "tilp_toast",
        message: _this.$t('Metamask未安装'),
        position: 'bottom',
      });

    }
  },
  methods: {
    async linkWallet() {
      let _this = this
      if (!window.ethereum) {
        return showToast({
          className: "tilp_toast",
          message: this.$t('Metamask未安装'),
          position: 'bottom',
        });
      }
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        this.$store.commit('setAccount', accounts[0])

      } catch (error) {
        this.$store.commit('setAccount', '');
        this.$store.commit('setTabActINdex', '0')
        throw error
      }
    },
  }
}
</script>

<style>
#app {
  width: 100%;
  height: 100%;
}
</style>
