import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import i18n from './language/index'
import vant from 'vant';
import 'vant/lib/index.css';
import './assets/css/style.css'
import { copy, formdata, roundUpToTwoDecimals, getTime,formath,formatNumber,formath2 } from './assets/script/utils'
import { jia, jian, cheng, chu } from './assets/script/decimal'
import Home from "./http/index.js";
const app = createApp(App);
app.config.warnHandler = (msg, vm, trace) => {
    // 忽略警告或进行自定义处理
};

const globalProperties = {
    $formdata: formdata,
    $roundUpToTwoDecimals: roundUpToTwoDecimals,
    $getTime: getTime,
    $copy: copy,
    $jia: jia,
    $jian: jian,
    $cheng: cheng,
    $chu: chu,
    $http: Home,
    $formath:formath,
    $formath2:formath2,

    $formatNumber:formatNumber,
    $BaseUrl:"https://api.starlink99.com/"
};
app.config.globalProperties = { ...app.config.globalProperties, ...globalProperties };

app.use(store)
app.use(i18n)
app.use(router)
app.use(vant)
app.mount('#app')
