import { createI18n } from 'vue-i18n'
import store from '../store'

const i18n = createI18n({
    legacy: true,
    globalInjection: true,
    locale: store.state.lang || 'zh-CN',
    fallbackLocale: 'zh-CN',
    messages: {
        'zh-CN': require('./lang/zh-CN.json'),
        'en': require('./lang/en.json'),
        'ko': require('./lang/ko.json'),
        'vn': require('./lang/vn.json'),
        'ru': require('./lang/ru.json'),


    }
});

// 监听 store.state.lang 的变化
store.watch(() => store.state.lang, (newLang) => {
    // 手动更新 Vue I18n 的 locale
    i18n.global.locale = newLang;
});

export default i18n;
