// index.js 调用接口的方法
import { getD, postD } from './api'

class Home {

    static banner(parme = {}) {
        return postD('banner', parme)
    }
    static infor(parme = {}) {
        return postD('infor', parme)
    }
    static rewardRecords(parme = {}) {
        return postD('rewardRecords', parme)
    }
    static declarationLog(parme = {}) {
        return postD('declarationLog', parme)
    }
    static addrInfor(parme = {}) {
        return postD('addrInfor', parme)
    }

    static binding(parme = {}) {
        return postD('binding', parme)
    }

    static notice(parme = {}) {
        return postD('notice', parme)
    }

    static noticeDetails(parme = {}) {
        return postD('noticeDetails', parme)
    }

    static price(parme = {}) {
        return postD('price', parme)
    }

    static getTopUpAddr(parme = {}) {
        return postD('getTopUpAddr', parme)
    }

    static TopUp(parme = {}) {
        return postD('TopUp', parme)
    }

    static TopUpLog(parme = {}) {
        return postD('TopUpLog', parme)
    }
    static receiveToken(parme = {}) {
        return postD('withdrawalCoins', parme)
    }

    static withdrawalCoinsLog(parme = {}) {
        return postD('withdrawalCoinsLog', parme)
    }

    static coinagelog(parme = {}) {
        return postD('coinagelog', parme)
    }

    static getK(parme = {}) {
        return postD('getK', parme)
    }

    static getConfig(parme = {}) {
        return postD('getConfig', parme)
    }
}

export default Home;