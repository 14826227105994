import { showToast } from 'vant';
import i18n from "@/language/index";
const $t = i18n.global.t
const copy = (url) => {
    var input = document.createElement("input"); // js创建一个input输入框
    input.value = url; // 将需要复制的文本赋值到创建的input输入框中
    document.body.appendChild(input); // 将输入框暂时创建到实例里面
    input.select(); // 选中输入框中的内容
    document.execCommand("Copy"); // 执行复制操作
    document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
    showToast({
        message: $t('复制成功'),
        position: 'bottom',
    });
}
const roundUpToTwoDecimals = (number) => {
    if (number) {
        let amount = Number(number)
        return parseFloat(Math.ceil(amount * 100) / 100);
    }
}

const formdata = (value) => {
    if (value) {
        const start = value.slice(0, 6);
        const end = value.slice(-4);
        return `${start}***${end}`;
    }
};
const getTime = (timestamp,type) => {
    const date = timestamp ? new Date(timestamp*1000) : new Date(); // 将时间戳转换为毫秒并创建Date对象
    const year = date.getFullYear(); // 获取年份
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // 获取月份，并在前面加0补齐两位数
    const day = ("0" + date.getDate()).slice(-2); // 获取日期，并在前面加0补齐两位数
    const hours = ("0" + date.getHours()).slice(-2); // 获取小时，并在前面加0补齐两位数
    const minutes = ("0" + date.getMinutes()).slice(-2); // 获取分钟，并在前面加0补齐两位数
    const seconds = ("0" + date.getSeconds()).slice(-2); // 获取秒钟，并在前面加0补齐两位数
    // const formattedDate = `${year}-${month}-${day}`; // 格式化日期字符串
    let formattedDate;

    if (type == 1) {
        formattedDate = `${hours}:${minutes}`;
    }else if(type == 2){
        formattedDate = `${hours}:${minutes}`;
    }else if(type == 3){
        formattedDate = `${month}-${day}`;
    }else{
        formattedDate = `${year}-${month}-${day}`;
    }
    return formattedDate
}

const getASC = (params) => {
    const sortedKeys = Object.keys(params).sort((a, b) => a.localeCompare(b));
    let asc = "";
    sortedKeys.forEach((key) => {
      asc += key + '=' + params[key] + "&"
    });
    return asc;
  }

  const formath = (str)=>{
    const matchResult = String(str).match(/[0-9]+\.[0-9]{10}/);
    const result = matchResult ? matchResult[0] : "";
    return result

  }
  const formath2 = (str)=>{
    const matchResult = String(str).match(/[0-9]+\.[0-9]{7}/);
    const result = matchResult ? matchResult[0] : "";
    return result

  }

  function formatNumber(num) {
    let strNum = num.toString();
    let decimalPointIndex = strNum.indexOf('.');
    if (decimalPointIndex === -1) return strNum; // 如果没有小数部分，直接返回原数

    // 找到第一个非零数字的位置
    let nonZeroIndex = decimalPointIndex + 1;
    while (strNum[nonZeroIndex] === '0') {
        nonZeroIndex++;
    }

    const res =  strNum.replace(/\./g, '');


   const  res1=   Number(String(res).replace(/0/g, ''))


    // 计算非零数字前的零的数量
    let zeroCount = nonZeroIndex - (decimalPointIndex + 1);

    // 构建格式化后的字符串
    let formattedStr = `0.{${zeroCount}}${res1}`;

    return formattedStr;
}

export {
    copy,
    roundUpToTwoDecimals,
    formdata,
    getTime,
    getASC,
    formath,
    formatNumber,
    formath2
}