
import axios from 'axios' // 引用axios
import { showLoadingToast, closeToast, showToast } from 'vant';
import store from '@/store'
import md5 from 'js-md5';
import { getASC } from '@/assets/script/utils';
axios.defaults.baseURL = 'https://api.starlink99.com/api' // 这是调用数据接口,公共接口url+调用接口名
export function get(url, params = {}, isloading = true) {
  if (isloading) {
    showLoadingToast({
      message: '',
      forbidClick: true,
      duration: 0,
      iconSize: '60px',
    });
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params
      })
      .then(response => {
        closeToast()
        if (response.data.code != 200) {
          showToast({
            message: response.data.msg,
            position: 'bottom',
          });
        }
        resolve(response.data)
      })
      .catch(err => {
        closeToast()
        reject(err)
      })
  })
}
// 封装post请求
export function post(url, data = {}, isloading = true) {
  let account = store.state.account
  let lang = store.state.lang
  const keys = "3E0F6b095BFFD24221fC9447466FBE67b0cdc342";
  const keys2 = "95ea7b3fb2fa9090925cefa80784481d637aa159e00109321e19e0c9bab24";
  const headers = {
    'Content-Type': 'application/json',
    'lang':lang,
    'k': keys,
    "v": md5(account + getASC(data) + keys2),
    "address": account
  }

  if (isloading) {
    showLoadingToast({
      message: '',
      forbidClick: true,
      duration: 0,
      iconSize: '60px',
    });
  }
  return new Promise((resolve, reject) => {
    axios.post(url, data,{headers}).then(
      response => {
        // console.log(response.data.code)
        closeToast()
        resolve(response.data)
      },
      err => {
        closeToast()
        reject(err)
      }
    )
  })
}